import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Search, X, Shuffle, Linkedin, RotateCcw, ExternalLink, ChevronLeft, ChevronRight } from 'lucide-react';

const RESOURCES_URL = '/resources.json';

const deepSearch = (obj, searchTerm) => {
  if (!obj) return false;

  return Object.values(obj).some(value => {
    if (typeof value === 'string') {
      return value.toLowerCase().includes(searchTerm.toLowerCase());
    }
    if (typeof value === 'object') {
      return deepSearch(value, searchTerm);
    }
    return false;
  });
};

const Carousel = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 5000);
    return () => clearInterval(timer);
  }, [items]);

  return (
    <div className="relative w-full h-64 mb-8 overflow-hidden rounded-lg shadow-lg">
      {items.map((item, index) => (
        <div
          key={item.id}
          className={`absolute top-0 left-0 w-full h-full transition-opacity duration-500 ${index === currentIndex ? 'opacity-100' : 'opacity-0'
            }`}
        >
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-600 opacity-75"></div>
          <div className="relative z-10 flex flex-col justify-center h-full text-white p-6">
            <h2 className="text-2xl font-bold mb-2">{item.name}</h2>
            <p className="mb-4">{item.description}</p>
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-white text-blue-600 font-medium py-2 px-4 rounded hover:bg-gray-100 transition-colors"
            >
              Learn More
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

const Modal = ({ isOpen, onClose, resource }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-4">{resource.name}</h2>
          <p className="text-gray-600 mb-4">{resource.description}</p>

          {resource.more_info && Object.entries(resource.more_info).map(([key, value]) => (
            <div key={key} className="mb-3">
              <h3 className="text-lg font-semibold capitalize">{key.replace('_', ' ')}</h3>
              <p className="text-gray-700">{value}</p>
            </div>
          ))}

          <div className="flex justify-end mt-6 gap-4">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
            >
              Close
            </button>
            <a
              href={resource.url}
              target="_blank"
              rel="noopener noreferrer"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors flex items-center"
            >
              Visit Site <ExternalLink size={18} className="ml-2" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Footer = () => (
  <footer className="bg-gray-800 text-white py-4 mt-8">
    <div className="container mx-auto px-4 flex justify-between items-center">
      <p className="text-sm">&copy; {new Date().getFullYear()} Made with 💛 by David An + Barry Rabkin</p>
      <div className="flex space-x-4">
        {/* <a href="https://github.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">
          <Github size={20} />
        </a> */}
        <a href="https://linkedin.com/in/davidzan" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">
          <Linkedin size={20} />
        </a>
      </div>
    </div>
  </footer>
);

const App = () => {
  const [resources, setResources] = useState([]);
  const [shuffledResources, setShuffledResources] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [modalResource, setModalResource] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isShuffled, setIsShuffled] = useState(false);
  const resourcesPerPage = 9;

  useEffect(() => {
    fetch(RESOURCES_URL)
      .then(response => response.json())
      .then(data => {
        const sortedData = data.sort((a, b) => a.id - b.id);
        setResources(sortedData);
        setShuffledResources(sortedData);
      });
  }, []);

  const tags = useMemo(() => {
    const allTags = [...new Set(resources.flatMap(resource => resource.tags))];
    return allTags.sort((a, b) => a.localeCompare(b));
  }, [resources]);

  const filteredResources = useMemo(() => {
    const sourceResources = isShuffled ? shuffledResources : resources;
    return sourceResources.filter(resource => 
      (searchTerm === '' || deepSearch(resource, searchTerm)) &&
      (selectedTags.length === 0 || selectedTags.every(tag => resource.tags.includes(tag)))
    );
  }, [resources, shuffledResources, searchTerm, selectedTags, isShuffled]);

  const highlightedResources = useMemo(() => {
    return resources.filter(resource => resource.highlighted).slice(0, 5);
  }, [resources]);

  const currentResources = useMemo(() => {
    const indexOfLastResource = currentPage * resourcesPerPage;
    const indexOfFirstResource = indexOfLastResource - resourcesPerPage;
    return filteredResources.slice(indexOfFirstResource, indexOfLastResource);
  }, [filteredResources, currentPage]);

  const toggleTag = useCallback((tag) => {
    setSelectedTags(prevTags =>
      prevTags.includes(tag)
        ? prevTags.filter(t => t !== tag)
        : [...prevTags, tag]
    );
  }, []);

  const clearTags = useCallback(() => {
    setSelectedTags([]);
  }, []);

  const shuffleResources = useCallback(() => {
    const shuffled = [...resources].sort(() => 0.5 - Math.random());
    setShuffledResources(shuffled);
    setIsShuffled(true);
    setCurrentPage(1);
  }, [resources]);

  const resetResources = useCallback(() => {
    setSearchTerm('');
    setSelectedTags([]);
    setIsShuffled(false);
    setCurrentPage(1);
  }, []);

  const openModal = useCallback((resource) => {
    setModalResource(resource);
  }, []);

  const closeModal = useCallback(() => {
    setModalResource(null);
  }, []);

  const paginate = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <div className="container mx-auto px-4 py-8 flex-grow">
        <h1 className="text-4xl font-extrabold text-gray-900 mb-2 text-center">Pittsburgh</h1>
        <h2 className="text-3xl font-bold text-blue-600 mb-8 text-center">Startup Compass</h2>
        
        {/* <Carousel items={highlightedResources} /> */}
        
        <div className="max-w-2xl mx-auto mb-6">
          <div className="relative">
            <input
              type="text"
              placeholder="Search across all fields..."
              className="w-full py-3 px-12 bg-white rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
            {searchTerm && (
              <button
                onClick={() => setSearchTerm('')}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
              >
                <X size={18} />
              </button>
            )}
          </div>
        </div>
        
        <div className="flex flex-wrap justify-center gap-2 mb-6">
          <button
            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
              selectedTags.length === 0 
                ? 'bg-blue-500 text-white' 
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
            onClick={clearTags}
          >
            All
          </button>
          {tags.map(tag => (
            <button
              key={tag}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                selectedTags.includes(tag)
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
              onClick={() => toggleTag(tag)}
            >
              {tag}
            </button>
          ))}
        </div>
        
        <div className="flex justify-center gap-4 mb-8">
          <button
            onClick={shuffleResources}
            className="bg-purple-500 text-white px-6 py-3 rounded-full font-medium flex items-center hover:bg-purple-600 transition-colors"
          >
            <Shuffle className="mr-2" size={18} />
            Shuffle Resources
          </button>
          <button
            onClick={resetResources}
            className="bg-blue-500 text-white px-6 py-3 rounded-full font-medium flex items-center hover:bg-blue-600 transition-colors"
          >
            <X className="mr-2" size={18} />
            Reset Filters
          </button>
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 auto-rows-auto gap-4">
          {currentResources.map(resource => (
            <div
              key={resource.id}
              className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow flex flex-col cursor-pointer"
              onClick={() => openModal(resource)}
            >
              <div className="p-4 flex flex-col h-full">
                <h2 className="text-xl font-semibold text-gray-900 mb-2">{resource.name}</h2>
                <p className="text-gray-600 mb-4 flex-grow">{resource.description}</p>
                <div className="flex flex-wrap gap-2 mt-auto">
                  {resource.tags.map(tag => (
                    <span key={tag} className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded">
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination */}
        <div className="flex justify-center mt-8">
          <nav className="inline-flex rounded-md shadow">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-3 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
            >
              <ChevronLeft size={20} />
            </button>
            {[...Array(Math.ceil(filteredResources.length / resourcesPerPage)).keys()].map((number) => (
              <button
                key={number + 1}
                onClick={() => paginate(number + 1)}
                className={`px-4 py-2 border border-gray-300 text-sm font-medium ${
                  currentPage === number + 1
                    ? 'bg-blue-500 text-white'
                    : 'bg-white text-gray-500 hover:bg-gray-50'
                }`}
              >
                {number + 1}
              </button>
            ))}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === Math.ceil(filteredResources.length / resourcesPerPage)}
              className="px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
            >
              <ChevronRight size={20} />
            </button>
          </nav>
        </div>
      </div>
      <Footer />
      <Modal isOpen={!!modalResource} onClose={closeModal} resource={modalResource} />
    </div>
  );
};


export default App;